import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="main">
        <div className="error-content-wrap text-center">
          <div className="error-code">404</div>
          <h1 className="error-message h3">Сторінка не знайдена</h1>
          <p className="message-manual">
            Можливо URL введено неправильно, або сторінка вже не існує.
          </p>
          <Link to="/" className="btn">
            Повернутись на головну
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
